@import "../../styles/variables.scss";
@import "../../styles/media.scss";

section.title-ribbon {
	background-color: $title-ribbon-bg;
	padding-top: 0.8em;
	padding-bottom: 1em;
	margin-bottom: 1.7em;
	color: $title-ribbon-txt;
	& .box .cell h1,
	& h6 {
		font-weight: 100;
		font-size: 1.2em;
		margin: 0.5em 0 0 0;
		//opacity: 0.5;
		letter-spacing: 0;
		line-height: 1.2;
		text-transform: uppercase;
		& span {
			font-weight: 600;
			font-size: 1.03em;
			white-space: nowrap;
			&.normal {									// when <span className="normal"> is used to control heading wrapping only
				font-weight: 100;
				font-size: 1em;
			}
		}
		@include for-watch-only {
			color: $title-ribbon-txt;
			//opacity: 0.9;
			& span {
				white-space: normal;
			}
		}
		@include for-phone-only {
			text-align: center;
		}
	}
	&-bg {												// colours panel under header same colour so header expansion/contraction does not show white
		background-color: $title-ribbon-bg;
	}

}
@include for-tablet-landscape-up {
	section.title-ribbon {
		padding-top: 1em;
		padding-bottom: 1.5em;
		margin-bottom: 0em;	
		&-neat {
			margin-bottom: 0em;
		}
		& .box .cell h1,
		& h6 {
			font-size: 1.3em;
			margin: 0.5em 0 0em 0;
		}
	}
}

//@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.management-consulting-img {
	background-size: cover;
	background-position: 50% 30%;
	min-height: 300px;
	border: solid 1px #888F81;	
	box-shadow: 5px 10px 30px rgba(200, 200, 200, .4);
	z-index: 10;	
	background-repeat: no-repeat;							// required to avoid FireFox sub pixel bug
	@include for-tablet-landscape-up {
		min-height: 500px;
	}
}

// NOTE: due to rearrangement of layout, largest size is in the middle responsive layout as image gets wide before screen large enough to be side by side with text (and hence smaller)

//DEFAULT SM
.no-webp {
	& .management-consulting-img {	background-image: url('../../img/management-consulting-sm.jpg');	}
}
.webp {
	& .management-consulting-img {	background-image: url('../../img/management-consulting-sm.webp');	}
}

//LIGHTHOUSE TY
@include for-watch-only {
	.no-webp {
		& .management-consulting-img {	background-image: url('../../img/management-consulting-ty.jpg');	}
	}
	.webp {
		& .management-consulting-img {	background-image: url('../../img/management-consulting-ty.webp');	}
	}
}

//TABLET LG
@include for-tablet-portrait-up {
	.no-webp {
		& .management-consulting-img {	background-image: url('../../img/management-consulting-lg.jpg');	}
	}
	.webp {
		& .management-consulting-img {	background-image: url('../../img/management-consulting-lg.webp');	}
	}
}

// DESKTOP SM (due to layour being side by side with text, hence smaller.
@include for-tablet-landscape-up {	
	.no-webp {
		& .management-consulting-img {	background-image: url('../../img/management-consulting-sm.jpg');	}
	}
	.webp {
		& .management-consulting-img {	background-image: url('../../img/management-consulting-sm.webp');	}
	}
}

.flx-order-2nd-in-column { order: 2; }
@include for-tablet-landscape-up {
	.flx-order-2nd-in-column{ order: 0; }
}



.brand-study-box {
	border: solid 1px #CCC;	
	box-shadow: 5px 10px 30px rgba(200, 200, 200, .4);
	z-index: 10;
	padding: 4em;
	@include for-tablet-landscape-up {
		padding: 8em;
	}
  }
  .brand-study-img {
	justify-content: center;									// HACKy: Piggy backs the flex setting in .brand_box
	z-index: 10;
	width: 100%;
	height: 100%;
  }
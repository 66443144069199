@import "../../styles/variables.scss";

.sitemap {
	flex-direction: column;
	a:hover {
		color: $hero-highlight-bright;
	}
	.sub {
		margin-left: 2em;
	}
	.sub-sub {
		margin-left: 4em;
	}
}
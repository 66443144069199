// The pixel values were taken from the chrome responsive inspector as sensible defaults. The pixel value is divided by 16
// to get the em value for the media queries. em is the only value that behaves correctly when zoomed in and/or when using
// a non-standard font size.

//$mobile-s: 			'max-width: 20em';		// 320px
//$mobile-m: 			'max-width: 23.4375em';	// 375px
//$mobile-l: 			'max-width: 30em';		// 480px [OLD] 26.5625em';  425px
//$mobile-h:			'max-width: 36.25em';	// 580px

//$mobile-landscape: 'max-width: 48em) and (orientation: landscape';

//$tablet: 			'max-width: 48em';		// 768px

//$desktop-s: 		'max-width: 55em';		// 880px    was => 51.5625em	  // 825px
//$desktop-ms:		'max-width: 60em';		// 960px
//$desktop-m: 		'max-width: 64.0625em';	// 1025px
//$desktop-l: 		'max-width: 90em';		// 1440px

//$wide:				'max-width: 160em';			// 2560px
//$widest:			'min-width: 160em';		// 2560px

//$nav-burger:		'max-width: 55em';



@mixin for-watch-only {
	@media (max-width: 360px) { @content; }
}
@mixin for-phone-only {
	@media (max-width: 599px) { @content; }
}

@mixin for-phone-up {
	@media (min-width: 361px) { @content; }
}
@mixin for-tablet-portrait-up {
	@media (min-width: 600px) { @content; }
}
@mixin for-full-menu-up {							// shows full menu above this width  SETTING--hamburger
	@media (min-width: 880px) { @content; }
}
@mixin for-tablet-landscape-up {
	@media (min-width: 960px) { @content; }
}
@mixin for-desktop-up {
	@media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
	@media (min-width: 1800px) { @content; }
}

$debug:		'max-width: 1px';
//$debug:		'min-width: 1px';  // uncomment this to switch on global layout degugging


//		usage
//		.my-className {
// 			padding: 10px;
// 			@include for-desktop-up {
// 				padding: 20px;
// 			}
// 		}
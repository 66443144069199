@import "../../styles/variables.scss";
@import "../../styles/media.scss";

.header {
	display: flex;
	justify-content: center;
	height: $header-height--big;
	color: $header-fg--small;
	background-color: $header-bg--big;					// SMOOTH: counterintuitively, this is the BG of the big header. The small header colour is the ::before pseudo element. Allows opacity to control meaning smoother transitions.
	transition: transform $default-ease;
	padding-bottom: 8px;
	position: fixed;
	width: 100%;
	border-bottom: $header-border-bottom--small;
	top:-$header-height--diff;
	z-index: 100;
	font-family: 'RobotoFlex', sans-serif;

	// @include for-watch-only {						// LIGHTHOUSE: satisfy google lighthouse contrast requirements.
	// 	background-color: $hero-dim;
	// }

	// SMOOTH: OK, so this is how we transition the background colour smoothly. the :;after is the coloured background that is controlled by opacity.
	&::after {
		content: '';
		position: absolute;
		height: 100%;
		width: 100%;
		opacity: 0;
		transition: opacity $default-ease;
		top: 0;
		left: 0;
		z-index: -1;
		box-shadow: 0 -5px 15px 10px rgba(0,0,0,.2); // settings to extend shadow to edge of page.
	}
	.header--shadow &::after {
		opacity: 1;
	}
	&::before {
		content: '';
		position: absolute;
		background-color: $header-bg--small;
		height: 100%;
		width: 100%;
		opacity: 1;
		transition: opacity $default-ease;
		top: 0;
		left: 0;
		z-index: -1;
	}
	.header--big & {
		transform: translate(0, $header-height--diff);
		color: $header-fg--big;
		border-bottom: $header-border-bottom--big;
		&::before {
			opacity: 0;
		}
	}
	& .header-item--brand {
		& a {
			& .brand-box {
				display: flex;
				position: relative;
			}
		}
	}
}

.header-box {
	display: flex;
    flex-direction: row;
    justify-content: space-between;
	flex: 1 1 auto;
	max-width: $container-max-width;
	align-items: flex-end;
	padding-right: 2rem;	/* leave right margin as standard 4rem as items have margin settings based on the 4rem to keep away from hamburger anyway*/
	@include for-tablet-portrait-up {
		padding-right: 4rem;
	}
	transition: transform $default-ease;
	.header--big & {
		transform: translate(0, -$header-height--diff-half);
	}
}




.header-item {
	display: flex;
	flex: 0 1 auto;
	& button {
		height: 20px;
		margin: 7px 0 0 20px;
	}
}
.header-item--nav {
	flex: 1 1 auto;
	flex-direction: row;
	justify-content: flex-end;

	//background-color: rgba($color: magenta, $alpha: 0.05);
	
	/*	IMPORTANT - SIZE OF .header-item--nav

		SETTING 1
		max-width: calc(100% - 200px);
		This will expand the nav menu box to the width available when the header is default (collapsed) and the logo it at its smallest
		
		SETTING 2
		max-width: calc(100% - ( (#{$header-logo-max-width} * #{$header-logo-scale}) + #{$nav-logo-gap} ) );
		This will expand the nav box to the width available when the logo is at its biggest (i.e. teh big header).
		
		OPTIONS
		You can always run the width just to the big header so it never resizes.
		Or you can use the wider default and pop the narrower setting in the .header--big & {} selector.

		Note $nav-logo-gap spacer. Keeps nave menu away from touching logo at max widths.

		max-width: calc(100% - 200px);
		.header--big & {
			max-width: calc(100% - ( (#{$header-logo-max-width} * #{$header-logo-scale})  + #{$nav-logo-gap} ) );
		}
		*/

		//Always size the nav box to the max available under the big header (i.e. the msallest nav box to avoid resize jump).
		max-width: calc(100% - ( (#{$header-logo-max-width} * #{$header-logo-scale}) + #{$nav-logo-gap} ) );
		// END - IMPORTANT - SIZE OF NAV BOX
		& .menu-box {
			max-width: $nav-max-width;
		}

}

.menu-box {
	flex-grow: 1;
}
@import "../../styles/variables.scss";


.loader {
	padding: 50px;
	width: 100%;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	& .spinner--oval {
		stroke: $hero;
	}
}
.pinky .loader {
	padding: 50px;
	width: 100%;
	background-color: magenta;
	display: flex;
	flex-direction: column;
	align-items: center;
	& .spinner--oval {
		stroke: #FFF;
	}
}


@import "../../styles/variables.scss";
@import "../../styles/media.scss";

section.breadcrumb-trail {
	background-color: $title-ribbon-bg;
	padding: 1.4em 0 1.0em 0;
	margin-bottom: 0.4em;
	color: $nav-sub-txt;
	& .bct-menu {
		align-items: center;
	}
	& .bct-separator {
		font-size: 2em;
		color: #BBB;
	}	
	& .box .cell a,
	& .box .cell span {
		font-weight: 100;
		font-size: 0.9em;
		margin: 0.1em 0 0 0;
		letter-spacing: 0;
		line-height: 1.2;
		text-transform: uppercase;		
		white-space: normal;
	}
	& .box .cell a:visited {
		color: $nav-sub-txt;
	}
	& .box .cell a:hover {
		color: $nav-sub-txt-hover;
	}
}
@include for-tablet-landscape-up {
	section.breadcrumb-trail {
		padding: 1.9em 0 1.5em 0;
		margin-bottom: 0em;	
		
		& .box .cell a,
		& .box .cell span {
			font-size: 1.3em;
		}
		& .box .cell span {
			color: #BBB;
		}
	}
}

@import "../../styles/variables.scss";
@import "../../styles/media.scss";


/* MENU STYLES - mobile menu */
.hamburger-wrap {
	& .hamburger {
		position: absolute;
		top: $header-height--diff;	// SMOOTH header panel is larger than default (small) view and just pushed out the top of screen so place HB down to be in view. 
		right: 10px;
		z-index: 1;
		width: 56px;
		height: 56px;
		padding: 1rem;
		background: $hamburger-bg--small;
		// @include for-watch-only {			// LIGHTHOUSE: satisfy google lighthouse contrast requirements.
		// 	background: $hero-dim;
		// }
		align-items: center;
		justify-content: center;
		transition: transform $default-ease, background-color $default-ease;
		display: flex;
		cursor: pointer;
		@include for-full-menu-up {
			display: none;
		}
		& > div.hamburger-lines { 							/* Hamburger Line */
			position: relative;
			flex: none;
			width: 100%;
			height: 2px;
			background: $hamburger-fg--small;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: transform 0.4s ease;
			&::before,
			&::after {			 			 /* Hamburger Lines - Top & Bottom */
				content: '';
				position: absolute;
				z-index: 1;
				top: -10px;
				width: 100%;
				height: 2px;
				background: inherit;
				right: 0;					/* Fixes old iOS bug on hamburger buns being shifted right */
			}
			&::after {  					/* Moves Line Down just for after */
				top: 10px;
			}
		}
		.header--big & {
			background: $hamburger-bg--big;		
			transform: translateY(-$header-hamburger-offset-v--big);
			& > div.hamburger-lines { 								/* Hamburger Lines */
				background: $hamburger-fg--big;
			}
		}
		
		&.hamburger-open > div.hamburger-lines {  			/* Toggler Animation */
			transform: rotate(135deg);
			&::before,		  		  		/* Turns Lines Into X */
			&::after {
				top: 0;
				transform: rotate(90deg);
			}
		}
		&.hamburger-open:hover > div {		/* Rotate On Hover When Checked */
			transform: rotate(225deg);
		}
	}
	& .hamburger-menu {
		display: flex;
		position: fixed;
		z-index: 150;
		right: 0;
		min-width: 350px;
		overflow: hidden;
		align-items: center;
		justify-content: flex-start;
		background: $hamburger-menu-panel-bg;
		padding: 5px 15px;
		margin-top: 9px;
		opacity: 0;
		visibility: hidden;
		transition: visibility $default-ease, opacity $default-ease, transform $default-ease;
		@include for-phone-only {
			width: 100%; 						// required for mobile to utilise 100% via MQ in .hamburger-wrap .menu > div
			left: 0;							// this lets the overflow hide on the right in stupidly small screens.
		}
		font-size: 1.8rem;
		.header--big & {
			transform: translateY($header-height--diff-half)
		}

		& > ul.menu-panel,
		& > ul > li {
			list-style: none;
			font-size: 1em;
			padding: .3rem 1rem;
			z-index: 85;
			-webkit-tap-highlight-color: transparent;
			position: relative;
			cursor: pointer;
			
			// MAIN Menu
			&.active > a,
			&.active > div.menu-item-link {
				color: $hamburger-btn-txt-active;
			}
			& > a,
			& > div.menu-item-link {
				color: $hamburger-btn-txt;
				text-decoration: none;
				&:hover {
					color: $hamburger-btn-txt-hover;
				}
			}

			// SUB Menu
			& > ul {
				position: relative;
				z-index: 2100;
				padding-inline-start: 1em;
				& > li {
					list-style: none;
					padding: .3rem;
					&.active > a,
					&.active > div.menu-item-link {
						color: $hamburger-sub-txt-active;
					}
					& a,
					& div.menu-item-link {
						color: $hamburger-sub-txt;
						text-decoration: none;
						&:hover {
							color: $hamburger-sub-txt-hover;
						}
					}
				}
			}
		}
		
		
	}
	& .hamburger-menu--open {
		opacity: 1;
		visibility: visible;
		transition: visibility $default-ease, opacity $default-ease, transform $default-ease;		
	}
}
//@import "../../styles/variables.scss";
@import "../../styles/media.scss";



@keyframes slideInFromLeft {
	0% {	transform: translateX(-100%);	opacity: 0;		}
//	50% {	transform: translateX(-100%);	opacity: 0;		}
	100% {	transform: translateX(0);		opacity: 1;		}
}
@keyframes slideInFromLeftSlow {
	0% {	transform: translateX(-100%);	opacity: 0;		}
	60% {	transform: translateX(-100%);	opacity: 0;		}		// this adds apparent delay to image entering.
	100% {	transform: translateX(0);		opacity: 1;		}
}

.slideImages {
	position: relative;
	min-height: calc( 75vw - 40px);
	//background-color: rgba(255, 255, 0, 0.5)!important;
	@include for-tablet-portrait-up {
		min-height: 400px;
		//background-color: rgba(255, 0, 255, 0.3)!important;
	}
}

.biggy {
	background-size: contain;
	background-repeat: no-repeat;	
	background-position: 50% 0;
	&.on-screen {
		animation: 1500ms ease-out 0s 1 slideInFromLeft;
		opacity: 1;
	}
}
.smalls {
	background-size: contain;
	background-repeat: no-repeat;	
	background-position: 50% 0;
	width: 100%;
	height: 100%;
	max-width: calc( 16vw + 50px);
	max-height: calc( 26vw + 90px);
	margin-top: calc(30vw - 50px);
	position: absolute;
	opacity: 0;
	&.on-screen {
		animation: 1500ms ease-out 0s 1 slideInFromLeftSlow;
		opacity: 1;
	}
	@include for-tablet-portrait-up {
		max-width: calc( 7vw + 80px);
		max-height: calc( 8vw + 200px);
		margin-top: calc(4vw + 100px);
	}
	@include for-desktop-up {
		margin-top: 150px;
		width: 170px;
		height: 300px;
	}
}

//DEFAULT SM
.no-webp {
	& .biggy 	{	background-image: url('../../img/MONITOR-SWW.png');	}
	& .smalls 	{	background-image: url('../../img/SMARTPHONE-SWW.png');	}
}
.webp {
	& .biggy 	{	background-image: url('../../img/MONITOR-SWW.webp');	}
	& .smalls 	{	background-image: url('../../img/SMARTPHONE-SWW.webp');	}
}


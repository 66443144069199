@import "../../styles/variables.scss";
@import "../../styles/media.scss";


.banner {
	display: flex;
	flex-direction: column;
	justify-content: space-around;

	&--base {
		justify-content: flex-end;
	}
	&--blank {												// pages that have no banner still need content pushed down under nav menu height
		flex: 0 0 auto;
	}
	&--map {												// pages that have MAP banner still - use taller banner area for map
		flex: 0 0 auto;
		//height: $main-top-small;	// unlike banner images, we can simply make thee map stretch under the header inso avoiding the resize and reduce jankiness
		background-color: #E5E3DF;		// map background default
	}
	&--slider {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: relative;
		background-size: cover;
		z-index: 1;
		padding-top: 0;
		margin-top: 0;
	}
}
// see div.non-header for all content container with translate based on header.
// @import "../../styles/variables.scss";
@import "../../styles/media.scss";

.software-services-img {
	background-size: cover;
	background-position: 50% 30%;
	min-height: 300px;
	border: solid 1px #888F81;	
	box-shadow: 5px 10px 30px rgba(200, 200, 200, .4);
	z-index: 10;	
	background-repeat: no-repeat;							// required to avoid FireFox sub pixel bug
	@include for-tablet-landscape-up {
		min-height: 500px;
	}
}

// up to 599px
// up to 959px
// above 960px
// As smallest size is no significant difference to largest... just use one size.
.no-webp {
	& .software-services-img {	background-image: url('../../img/cfo-services-sm.jpg');	}
}
.webp {
	& .software-services-img {	background-image: url('../../img/cfo-services-sm.webp');	}
}
@use "sass:math";

// CLIENT BASE PALLETTE
// https://mycolor.space/?hex=%23AD5C10&sub=1
// Also see the ColourPalette component

$client-orange:		#ad5c10;	// KEY
$client-red:		#B14344;	// SUPPORT
$client-maroon:		#984169;	// FOCUS
$client-purple:		#6C4979;	// (Focus Dark)
$client-blue:		#466b83;	// CONTRAST	
$client-charcoal:	#2F4858;	// (Contrast Dark)


// CLIENT BROAD PALLETTE
// KEY
$client-key-pale:				rgba(173, 92, 16, 0.1);
$client-key-bright: 			#ffae00;
$client-key-lighter:			#bd7028;						// slightly lighter than key colour
$client-key:					#ad5c10;
$client-key-dim:				#9b5009;
$client-key-dark:				#693504;
// SUPPORT
$client-support-bright:			#dd4447;
$client-support:				#B14344;
$client-support-dark:			#7c2527;
// FOCUS
$client-focus-pale:				rgba(152, 65, 105, 0.6);
$client-focus-bright:			#d42c6d;
$client-focus-lighter:			#af4978;
$client-focus:					#984169;
$client-focus-dark:				#6C4979;
// CONTRAST
$client-contrast-pale:			rgba(65, 76, 112, .1);
$client-contrast-bright:		#449dd1;
$client-contrast-lighter:		#4a7ea1;
$client-contrast:				#466b83;
$client-contrast-dark:			#2F4858;




// NAMED COLOURS - do a find and replace if names need to change by client
$hero-pale:				magenta;
$hero-light:			$client-key-lighter;
$hero-bright:			$client-key-bright;
$hero:					$client-key;
$hero-dim:				$client-key-dim;
$hero-strong:			magenta;
$hero-ultra-light:		magenta;
$hero-dark:				$client-key-dark;
$hero-hover:			$hero-dark;

$hero-contrast:			$client-focus-bright;		// google map info window links
$hero-highlight:		$client-contrast;
$hero-highlight-bright:	$client-focus-bright;
$hero-highlight-heavy:	$client-contrast-dark;
$hero-highlight-dark:	$client-contrast-dark;

// REFINED NAMED COLOURS
$contrast:				$client-contrast;
$contrast--bright:		$client-contrast-bright;
$contrast--hover:		$client-contrast-bright;
$contrast--dark:		$client-contrast-dark;

$support--bright:		$client-support-bright;
$support:				$client-support;
$support--hover:		$client-support-dark;

$focus:				$client-focus;
$focus--bright:		$client-focus-bright;
$focus--hover:		$client-focus-dark;

// GENERIC
$ultra-light-grey: 		#F8F8F8;
$light-grey: 			#DFDFDF;
$mid-grey: 				#828282;
$slate-grey: 			#474A4D;
$dark-slate-grey: 		#3A3B3D;
$warm-black: 			#181415;
$solid-black:			#000000;
$solid-white:			#FFFFFF;
$alpha-white-01:		rgba(255, 255, 255, 0.1);
$alpha-white-02:		rgba(255, 255, 255, 0.2);
$alpha-white-03:		rgba(255, 255, 255, 0.3);
$alpha-white-04:		rgba(255, 255, 255, 0.4);
$alpha-white-06:		rgba(255, 255, 255, 0.6);
$alpha-white-08:		rgba(255, 255, 255, 0.8);
$warning-text:			#ee6644;

// ASSIGNED COLOURS
$header-bg--big:				#FFF;
$header-bg--small:				#FAFAFA;								// look for this in .header::before
$header-fg--big:				$slate-grey;
$header-fg--small:				$header-fg--big;							// (currently) inherits same FG colour as desktop
$header-border-bottom--big:		$hero solid 1px;
$header-border-bottom--small:	$client-key-bright solid 1px;

$title-ribbon-bg:				#F8F8F8;
$title-ribbon-txt:				$warm-black;

// HAMBURGER & HB MENU
$hamburger-bg--big:				$header-bg--big;							// background behind hamburger bars for desktop nav
$hamburger-fg--big:				$hero;										// colour of hamburger bars for desktop nav
$hamburger-bg--small:			$header-bg--small;
$hamburger-fg--small:			$hero;
$hamburger-menu-panel-bg:		$hero;
$hamburger-btn-txt:				#FFF;
$hamburger-btn-txt-hover:		#ffd700;
$hamburger-btn-txt-active:		$alpha-white-06;
$hamburger-sub-txt:				$hamburger-btn-txt;
$hamburger-sub-txt-hover:		$hamburger-btn-txt-hover;
$hamburger-sub-txt-active:		$hamburger-btn-txt-active;
// Nav Desktop
$nav-btn-active--big:			rgba(100,100,100,.03);
$nav-btn-hover--big:			rgba(100,100,100,.04);
$nav-btn-txt--big:				$header-fg--big;							// Button txt in main menu (currently) inherits same FG colour as desktop.
$nav-btn-txt-hover--big:		$hero;										// HOVER Button txt in main menu (currently) uses hero.
$nav-btn-txt-active--big:		$header-fg--big;							// ACTIVE txt in main menu (currently) inherits same FG colour as desktop. (active indicated in BTN BG)
// Nav Small (e.g. desktop scrolled)
$nav-btn-active--small:			$nav-btn-active--big;						// inherit 'big' settings
$nav-btn-hover--small:			$nav-btn-hover--big;						// inherit 'big' settings
$nav-btn-txt--small:			$nav-btn-txt--big;							// inherit 'big' settings
$nav-btn-txt-hover--small:		$nav-btn-txt-hover--big;					// inherit 'big' settings
$nav-btn-txt-active--small:		$nav-btn-txt-active--big;					// inherit 'big' settings
// Sub Menu (Big and Small).
$nav-sub-txt:					$header-fg--big;							// Button txt in SUB menu (currently) inherits same FG colour as desktop.
$nav-sub-txt-hover:				$client-focus-bright;						// HOVER Button txt in SUB menu (currently) custom.
$nav-sub-txt-active:			$mid-grey;									// ACTIVE Button txt in SUB menu (currently) light grey (different to main menu which has button bg colour).



$footer-bg:						$dark-slate-grey;

$fast-ease:						100ms ease;
$rapid-ease:					300ms ease;
$default-ease:					500ms ease;
$casual-ease:					1300ms ease;
$slow-ease:						2000ms ease;
$page-max-width:				1140px;
$page-max-width--half:			math.div($page-max-width,2);
$box-side-pad:					2em;

$container-max-width:			$page-max-width;
//$container-max-width--half:		$page-max-width--half;
$showcase-max-width:			1220px;
$small-header-below:			480px;


/*
	IMPORTANT
	Several vars are used in App.js to determin scrolling, page width etc.
	You may need to adjust the vars in that page to marry with the header settings you change below.
	Particularly, look out for overflowPixels > 200 which says there must be 200px or more of content scrooling before the header will collaps.
	This stops the page jumping when the content is only just enough to trigger header collapse but not enough to hold the page with a short header.
*/

$header-height--big:				200px;
$header-height--small:				70px;
$header-height--diff:				$header-height--big - $header-height--small;
$header-height--diff-half:			math.div($header-height--diff, 2)+5;	//($header-height--diff/2)+5;
$header-hamburger-offset-v--big:	math.div($header-height--diff, 2)-5;		//($header-height--diff/2)-5;

$header-logo-max-width:			200px;									// AT SMALL SCALE!!!!  required for FireFox. Measure from chrome.
$header-logo-base-pad:			5px;									// AT SMALL SCALE!!!!  Lifts off flex-end when logo smaller than height available so it is centred.
$header-logo-scale:				2;										// How big the logo should get when scaled up for big header. Starting size to fit small menu. May need to add more control there.
$header-logo-scale-position-x:	(math.div(($header-logo-scale - 1),2)) * 100%;	// SHOULD BE   (($header-logo-scale - 1) / 2)    - this movse the logo to stay left aligned across scales. the 100% adds the % unit whcih is required.

// Nav Menu
$nav-height--big:				45px;
$nav-height--small:				45px;
$nav-logo-gap:					35px;									// space between the logo and the name menu. Adjsut per client.
$nav-max-width:					500px;									// optional hard max width for nav menu. adjust based o number nd size of menu items per client.

// BANNER
$main-top-normal:			$header-height--big;
$main-top-small:			$header-height--small;
$main-top-mobile:			$header-height--small;

$teaser-column-fill: 		#FFFF;
@use "sass:math";
@import "../../styles/variables.scss";
@import "../../styles/media.scss";

[class^="slider-"],
[class*=" slider-"] {
	background: $hero;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: 300;
	position: relative;
	max-height: calc(485px + #{$main-top-small});
	height: 70vw;
	padding: 5px 0;
	font-size: 6vw;
	& .headings-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}
	& h2,
	& h3 {
		font-family: 'RobotoFlex', sans-serif;
		font-weight: 100;
		text-align: center;
		& span:not([class]),
		&.bolded {
			font-family: 'RobotoFlex', sans-serif;
			font-weight: 850;
			letter-spacing: 0.05em;
			font-stretch: 70%;
		}
	}
	& h2 {
		font-size: 1.0em;
		line-height: 1.1em;
		text-transform: uppercase;
		font-weight: 300;
		margin: 0.5em 0 0 0;
	}
	& h3 {
		font-size: 0.8em;
		margin: 0.2em 0;
		line-height: 1.1em;
	}
	& .cta {
		font-size: 0.4em;
		margin: 1em 0;
	}
	@include for-tablet-portrait-up {
		& .cta {
			font-size: 0.3em;
		}
	}
	@include for-tablet-landscape-up {
		font-size: 5rem;
		//padding: 20px 0;
	}
}

@keyframes slideInFromLeft {
	0% {	transform: translateX(-100%);	opacity: 0;		}
	10% {	transform: translateX(-100%);	opacity: 0;		}
	100% {	transform: translateX(0);		opacity: 1;		}
}
@keyframes slideInFromLeftSlow {
	0% {	transform: translateX(-100%);	opacity: 0;		}
	50% {	transform: translateX(-100%);	opacity: 0;		}
	100% {	transform: translateX(0);		opacity: 1;		}
}
@keyframes slideInFromBelow {
	0% {	transform: translateY(200%);	opacity: 0;		}
	100% {	transform: translateY(0);		opacity: 1;		}
}
@keyframes fadeInLast {
	0% {	opacity: 0;	}
	60% {	opacity: 0;	}
	100% {	opacity: 1;	}
}
@keyframes fadeOutFast {
	0% {	opacity: 1;	}
	100% {	opacity: 0;	}
}

.peace-of-mind-snowscape {
	font-size: 4.0vw;	
	justify-content: flex-start;										// horizontally align single line items
	&:not(.active) h2, &:not(.active) h3, &:not(.active) .cta {		// negation of .active important of old iOS
		opacity: 0;													// as we handle opacity here (for inactive state) ...
		transition-delay: 1s;
	}
	& .cta {
		font-size: 0.7em;
	}
	& h2 {
		text-shadow: 0px 1px 8px rgba(36, 76, 109, 0.4);
		font-size: 1.70em;
		margin: 0.3em 0 0 0;
		&.bolded {
			text-shadow: 2px 4px 7px rgba(36, 76, 109, .2);
			margin: 0.1em 0 0.08em 0;
			font-size: 2.1em;
		}	
	}
	& h3 {
		color: $client-contrast;
		font-weight: 350;
		font-stretch: 117%;
		font-size: 1.4em;	
	}
	@include for-phone-up {
		& h2 {
			font-size: 1.95em;
			&.bolded {
				font-size: 2.5em;
			}	
		}		
		& h3 {
			font-size: 1.6em;	
			margin: 0.2em 0 0 0;
		}
	}
	@include for-tablet-portrait-up {
		& h2 {
			//font-size: 1.8em;
			font-weight: 200;
			&.bolded {
				margin: 0;
				//font-size: 2.23em;
			}	
		}
		& h3 {
			font-size: 1.45em;
			margin: 0.1em 0 0 0;
			font-weight: 200;
			font-stretch: 125%;
		}
		& .cta {
			font-size: 1.2rem;							// max font
		}
	}
	@include for-tablet-landscape-up {	
		font-size: 5rem;								// max font size using rem (controls cta button size)
		& h2 {
			font-size: 8rem;							// max font size using rem
			margin: 0.1em 0 0 0;
			&.bolded {
				margin: 0;
				font-size: 9.5rem;						// max font size using rem
			}	
		}
		& h3 {
			font-size: 6rem;							// max font size using rem
			margin: 0.1em 0 0.5em 0;
			font-weight: 150;
			font-stretch: 125%;
		}
	}

	&.active h2 {
		animation: 2300ms ease-out 0s 1 slideInFromLeft;
		opacity: 1;							// we also need to handle opacity here (MUST be after animation for iOS) for the state when active (after annimation is complete)
	}
	&.active h3 {
		animation: 3600ms ease-out 0s 1 slideInFromLeftSlow;
		opacity: 1;
	}
	&.active .cta {
		animation: 4600ms ease-out 0s 1 fadeInLast;
		opacity: 1;
	}
}


.robots-are-great {
	font-size: calc( 7.0vw + 0.3em ); 					// bigger the SUBRTRACTION, the MORE the scale (shrink) effect... bigger the ADDITION, the LESS the scale (shrink) effect
	@include for-desktop-up {	
		font-size: 8.68rem;								// max font size using rem
	}
	align-items: flex-end;
	& .headings-box {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;		
		@media (max-width: 793px) {						// ROBOT-HEADINGS See corresponding ROBOT-IMAGE placement
			max-width: calc( 52vw );
			//background-color: rgba(lime, 0.5);
		}
		//background-color: rgba(magenta, 0.5);
		max-width: calc( 100vw - 380px );
		@include for-desktop-up  {
			max-width: calc( 50% + 228px );
			//background-color: rgba(rgb(0, 183, 255), 0.5);
		}
		& h2, & h3, & .cta {
			margin: 0 0 0 2vw;
			text-align: left;
			align-self: flex-start;
		}
		& h2, & h3 {
			text-shadow: 0px 2px 10px rgba(0,0,0,0.2), 0px 2px 3px rgba(0,0,0,0.1);
		}
		& h2 {
			font-size: 1em;
			line-height: 1.0em;
			& span.b2 {		display: block;	}										// span.b2 (& span.b3) control when headings break into 2 (or 3) lines.
			@media (min-width: 980px)  {
				font-size: 0.9em;
				& span.b2 {	display: inline;		}
			}
		}
		& h3 {
			font-size: 0.6em;
			line-height: 1em;
			font-weight: 200;
			//background-color: rgba(lime,0.4);
			& span.b3 {		display: block;		}
			& span.b2 {		display: inline;	}
			@media (min-width: 980px)  {
				font-size: 0.6em;
				font-weight: 100;
				& span.b3 {	display: inline;	}
				& span.b2 {	display: block;		}
			}
		}
		& .cta {
			font-size: 0.25em;
			margin-top: 1em;
			@include for-tablet-portrait-up  {
				font-size: 0.16em;
			}
		}
		
	}
	&:not(.active) h2, &:not(.active) h3, &:not(.active) .cta {		// negation of .active important for old iOS
		opacity: 0;
		transition-delay: 1s;
	}
	&.active h2 {
		animation: 2s ease-out 0s 1 slideInFromBelow;
		opacity: 1;
	}
	&.active h3 {
		animation: 2s ease-out 0s 1 slideInFromBelow;
		opacity: 1;
	}
	&.active .cta {
		animation: 3000ms ease-out 0s 1 fadeInLast;
		opacity: 1;
	}
}


.experience-counts {
	font-size: calc( 8.2vw - 0.1em ); 					// bigger the SUBRTRACTION, the MORE the scale (shrink) effect... bigger the ADDITION, the LESS the scale (shrink) effect
	@include for-full-menu-up {	
		font-size: 7rem;								// max font size using rem
	}
	align-items: flex-start;
	justify-content: center;
	& .headings-box {
		// background-color: rgba(lime,0.4);
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 55%;
		& h2, & h3, & .cta {
			color: #000;
			margin: 0;
			text-align: right;
			line-height: 1em;;
			& span.b2 {		display: block;	}						// span.b2 (& span.b3) control when headings break into 2 (or 3) lines.
			@media (min-width: 1370px)  {
				& span.b2 {	display: inline; }
			}
		}
		& h3 {
			font-weight: 200;
			@media (min-width: 980px)  {
				font-weight: 100;
			}
		}
		& .cta {
			align-self: flex-end;
			font-size: 0.25em;
			margin-top: 1em;
		}
	}
	&:not(.active) h2, &:not(.active) h3, &:not(.active) .cta {		// negation of .active important for old iOS
		opacity: 0;
		transition-delay: 1s;
	}
	&.active h2 {
		animation: 2s ease-out 0s 1 slideInFromBelow;
		opacity: 1;
	}
	&.active h3 {
		animation: 2s ease-out 0s 1 slideInFromBelow;
		opacity: 1;
	}
	&.active .cta {
		animation: 3000ms ease-out 0s 1 fadeInLast;
		opacity: 1;
	}
}




.training-software {	
	font-size: calc( 8.2vw - 0.1em ); 					// bigger the SUBRTRACTION, the MORE the scale (shrink) effect... bigger the ADDITION, the LESS the scale (shrink) effect
	@include for-full-menu-up {	
		font-size: 7rem;								// max font size using rem
	}
	align-items: center;
	justify-content: center;
	& .headings-box {
		//background-color: rgba(lime,0.4);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: unset;
		position: absolute;
		left: 4vw;
		& h2, & h3, & .cta {
			//color: #000;
			margin: 0;// 0 0 5vw;
			text-align: left;
			line-height: 1em;;
			& span.b2 {		display: block;	}						// span.b2 (& span.b3) control when headings break into 2 (or 3) lines.
		}
		@media (min-width: 1230px)  {
			& h2 span.b2, & h3 span.b2 {	display: inline; }
			left: calc( 49vw - #{$page-max-width--half} );
			
		}
		& h2 {
			text-shadow: 0px 2px 10px rgba(0,0,0,0.3), 0px 2px 3px rgba(0,0,0,0.2);
		}
		& h3 {
			text-shadow: 1px 1px 5px rgba(255, 255, 255, 0.9), 1px 2px 10px rgba(255, 255, 255, 0.4);
			font-weight: 400;
			font-size: 0.7em;
			color: rgb(5, 46, 80);
			@include for-tablet-portrait-up {
				text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4), 1px 2px 10px rgba(0,0,0,0.4);
				font-weight: 200;
				color: #FFF;
			}
			// @media (min-width: 980px)  {
			// 	font-weight: 200;
			// }
		}
		& .cta {
			align-self: flex-start;
			font-size: 0.25em;
			margin-top: 1em;
		}
	}
	&:not(.active) h2, &:not(.active) h3, &:not(.active) .cta {		// negation of .active important for old iOS
		opacity: 0;
		transition-delay: 1s;
	}
	&.active h2 {
		animation: 2s ease-out 0s 1 slideInFromBelow;
		opacity: 1;
	}
	&.active h3 {
		animation: 2s ease-out 0s 1 slideInFromBelow;
		opacity: 1;
	}
	&.active .cta {
		animation: 3000ms ease-out 0s 1 fadeInLast;
		opacity: 1;
	}
}






.dots {
	display: flex;
	width: 100%;
	padding: 0 0 8px 0;
	position: absolute;
	bottom: 0;
	justify-content: center;
	@include for-watch-only {
		display: none;
	}
}
.dot {
	border: none;
	width: 10px;
	height: 10px;
	background: #EEE;
	border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
	cursor: pointer;
}
.dot:focus {
	outline: none;
}
.dot.active {
	background: $hero;
}
// DOTs - see custom active colour in each slider definition below.  ( [sliderClassName]&--dot.active)

.peace-of-mind-snowscape 	{ .no-webp &, .webp & { background-position: 50% 65%;	background-repeat: no-repeat;	background-size: cover;	} &--dot.active { background-color: $contrast; }	}
.experience-counts 			{ .no-webp &, .webp & { background-position: 50% 0%;	background-repeat: no-repeat;	background-size: cover;	} &--dot.active { background-color: $contrast--bright; }	}
.training-software 			{ .no-webp &, .webp & { background-position: 50% 37%;	background-repeat: no-repeat;	background-size: cover;	} &--dot.active { background-color: $support--bright; }	}
.robots-are-great {
	.no-webp &,
	.webp & {
		background: linear-gradient(54deg, #ffffff 0%, rgb(79, 156, 143) 100%);
		&::before {
			padding-top: 0;
			content: '';
			position: absolute;
			z-index: -50;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: contain;
			/*
				ROBOT-IMAGE POSITION
				very large screens - positioned where left contained margin bisects it (approx)
				Screen shrink he stays still until left viewport touches his neck then he stays in that position as screen shrinks further.
				This was complicated by the cover fit and the chaning height of the slider being 70vw & max-height  : calc(485px + 70px)
				ROBOT-IMAGE See corresponding ROBOT-HEADINGS placement
			*/	
			@media (max-width: 793px) {
				background-position: -24.3vw 0;
			}
			background-position: -193px 0;
			@include for-desktop-up  {
				background-position: calc(50% - #{$page-max-width--half} + 63px ) 0;
			}
		}
	}
	&--dot.active { background-color: $support--bright; }
}

.no-webp {
	& .peace-of-mind-snowscape 		{	background-image: url('../../img/sliders/peace-of-mind-software-sm.jpg');	}
	& .experience-counts 			{	background-image: url('../../img/sliders/codebse-working-with-customers-sm.jpg');	}
	& .training-software 			{	background-image: url('../../img/sliders/training-software-sm.jpg');	}
	& .robots-are-great::before		{	background-image: url('../../img/sliders/robots-are-great-iso-lg.png'); }
}
.webp {
	& .peace-of-mind-snowscape 		{	background-image: url('../../img/sliders/peace-of-mind-software-sm.webp');			}
	& .experience-counts 			{	background-image: url('../../img/sliders/codebse-working-with-customers-sm.webp');	}
	& .training-software 			{	background-image: url('../../img/sliders/training-software-sm.webp');	}
	& .robots-are-great::before		{	background-image: url('../../img/sliders/robots-are-great-iso-lg.webp'); }
}
@include for-watch-only {
	.no-webp {
		& .peace-of-mind-snowscape 		{	background-image: url('../../img/sliders/peace-of-mind-software-ty.jpg'); }
		& .experience-counts 			{	background-image: url('../../img/sliders/codebse-working-with-customers-ty.jpg'); }
		& .training-software 			{	background-image: url('../../img/sliders/training-software-ty.jpg'); }
		& .robots-are-great::before		{	background-image: url('../../img/sliders/robots-are-great-iso-ty.png');	}
	}
	.webp {
		& .peace-of-mind-snowscape 		{	background-image: url('../../img/sliders/peace-of-mind-software-ty.webp'); }
		& .experience-counts 			{	background-image: url('../../img/sliders/codebse-working-with-customers-ty.webp'); }
		& .training-software 			{	background-image: url('../../img/sliders/training-software-ty.webp'); }
		& .robots-are-great::before		{	background-image: url('../../img/sliders/robots-are-great-iso-ty.webp'); }
	}
}
@include for-tablet-portrait-up {
	.no-webp {
		& .peace-of-mind-snowscape {	background-image: url('../../img/sliders/peace-of-mind-software-md.jpg');		}
		& .experience-counts {	background-image: url('../../img/sliders/codebse-working-with-customers-md.jpg');	}
		& .training-software {	background-image: url('../../img/sliders/training-software-md.jpg');	}
//		& .robots-are-great			{	background-image: url('../../img/sliders/robots-are-great-md.jpg');	} 
	}
	.webp {
		& .peace-of-mind-snowscape {	background-image: url('../../img/sliders/peace-of-mind-software-md.webp');		}
		& .experience-counts {	background-image: url('../../img/sliders/codebse-working-with-customers-md.webp');	}
		& .training-software {	background-image: url('../../img/sliders/training-software-md.webp');	}
//		& .robots-are-great			{	background-image: url('../../img/sliders/robots-are-great-md.webp');	}
	}
}
@include for-desktop-up {
	.no-webp {
		& .peace-of-mind-snowscape 		{	background-image: url('../../img/sliders/peace-of-mind-software-lg.jpg');		}
		& .experience-counts 			{	background-image: url('../../img/sliders/codebse-working-with-customers-lg.jpg');	}
		& .training-software 			{	background-image: url('../../img/sliders/training-software-lg.jpg');	}
		//& .robots-are-great::before		{	background-image: url('../../img/sliders/robots-are-great-iso-lg.png'); }
	}
	.webp {
		& .peace-of-mind-snowscape 		{	background-image: url('../../img/sliders/peace-of-mind-software-lg.webp'); }
		& .experience-counts 			{	background-image: url('../../img/sliders/codebse-working-with-customers-lg.webp'); }
		& .training-software 			{	background-image: url('../../img/sliders/training-software-lg.webp'); }
		//& .robots-are-great::before		{	background-image: url('../../img/sliders/robots-are-great-iso-lg.webp'); }
	}
}
@include for-big-desktop-up {
	.no-webp {
		& .peace-of-mind-snowscape {	background-image: url('../../img/sliders/peace-of-mind-software-lg.jpg');		}
		& .experience-counts {	background-image: url('../../img/sliders/codebse-working-with-customers-hg.jpg');	}
		& .training-software {	background-image: url('../../img/sliders/training-software-hg.jpg');	}
//		& .robots-are-great			{	background-image: url('../../img/sliders/robots-are-great-hg.jpg');	}
	}
	.webp {
		& .peace-of-mind-snowscape {	background-image: url('../../img/sliders/peace-of-mind-software-lg.webp');		}
		& .experience-counts {	background-image: url('../../img/sliders/codebse-working-with-customers-hg.webp');	}
		& .training-software {	background-image: url('../../img/sliders/training-software-hg.webp');	}
//		& .robots-are-great			{	background-image: url('../../img/sliders/robots-are-great-hg.webp');	}
	}
}


.navigation-wrapper {
	position: relative;
}


.arrow {
	width: 30px;
	height: 120px;
	padding: 50px 5px;
	position: absolute;
	top: 57%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	fill: #fff;
	cursor: pointer;
}
.arrow--left {
	left: 0;
}
.arrow--right {
	left: auto;
	right: 0;
}	
@include for-full-menu-up {
	.arrow {
		width: 70px;
		height: 130px;
		padding: 50px 20px;
	}	
}
.arrow--disabled {
	fill: rgba(255, 255, 255, 0.5);
}

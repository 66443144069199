@import "../../styles/variables.scss";
@import "../../styles/media.scss";





// @keyframes fade-canvas-in {
// 	0% {	opacity: 0;	}
// 	100% {	opacity: 1;	}
// }

/* NAV STYLES - static desktop navigation menu */
.menu-canvas {
	display: block;
	//visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	// background-color: rgba(0, 0, 0, 0.05);	// Overlay when HB menu open. Option to use keyframe animation to fade in. Would need to think up delay in dropping DOM element for fade out.
	z-index: 80;
	opacity: 0;
	&--active {
		opacity: 1;
	}

}
.nav-wrap {
	display: none;
	flex: 1 1 auto;
	z-index: 1;
	@include for-full-menu-up {
		display: flex;
	}
	.nav-menu {
		display: flex;
		flex: 1 1 auto;
		flex-direction: row;
		justify-content: flex-end;
		align-self: center; 					// SEE: Menu-Items-Vertical-Position - aligns menu items to the middle vertically in the header
		padding: 0;
		min-width: 250px;
		width: 100%; 							// required for mobile to utilise 100% via MQ in .nav-wrap .menu > div
		height: $nav-height--small;
		& > ul.menu-panel {											// Nav Menu 1st level (main menu). Desktop (big and small)
			display: flex;
			flex: 1 1 auto;
			justify-content: space-between;
			list-style: none;
			padding: 0;
			max-width: 750px;
			margin: 0;
			-webkit-tap-highlight-color: transparent;
			& > li.menu-item {										// ITEM of Nav Menu 1st level (main menu). Desktop (big and small)
				& + li.menu-item { margin-left: 15px;}
				position: relative;
				font-size: 1.8rem;
				display: inline-block;
				z-index: 100;
				border-radius: 3px;
				text-align: center;
				flex-grow: 1;
				margin-left: 5px;
				& a,
				& div.menu-item-link {
					color: $nav-btn-txt--small;
					text-decoration: none;
					white-space: nowrap;
					font-weight: 200;
					font-stretch: expanded;
					width: 100%;
					display: inline-block;
					padding: 10px 0;
					-webkit-tap-highlight-color: transparent;
					cursor: pointer;
				}
				&.active {
					background: $nav-btn-active--small;
				}
				&:hover {
					& a,
					& div.menu-item-link {
						color: $nav-btn-txt-hover--small;
						text-decoration: none;
					}
					background-color: $nav-btn-hover--small;
					& > ul.sub-menu {
						display: flex;
						flex: 1 1 auto;
						flex-direction: column;
						align-items: flex-start;
						z-index: 10;
						& li.sub-menu-item {
							& a,
							& div.menu-item-link {
								// font-size: 0.9em;
								color: inherit;
								text-decoration: none;
								width: 100%;
								padding: 10px 0;
								-webkit-tap-highlight-color: transparent;
								cursor: pointer;
								&:hover {
									color: $nav-sub-txt-hover;
									//text-decoration: underline;
									//text-underline-position: under;
								}
							}
							&.active a,
							&.active div {
								color: $nav-sub-txt-active;		// Ordering this below the hover will mean active links are not highlighted on hover. Desired (for now).
							}
						}
					}
				}
				& > ul.sub-menu {
					position: absolute;
					list-style: none;
					display: none;
					top: 45px;
					left: 0px;
					z-index: 100;
					background-color: $solid-white;
					border-radius: 3px;
					box-shadow: 0 13px 15px -10px rgba(0,0,0,.2), 0 0 45px rgba(0,0,0,.1);
					padding: 25px 40px 35px 40px;

					// del this next bit to remove open nav always
					display: flex;
					flex: 1 1 auto;
					flex-direction: column;
					align-items: flex-start;
					z-index: 10;
					& > li.sub-menu-item {
						color: $nav-sub-txt;
					}

					& li > a,
					& li > div.menu-item-link {
						font-size: 0.9em;
						& svg {
							font-size: 1.4em;
							margin-right: 0.5em;
						}
						display: flex;
						flex-direction: row;
						align-items: center;
					}

				}
			}



		}
		// & > ul.menu-panel > li.menu-item.active {
		// 	background: $nav-btn-active--small;
		// }
		// & > ul.menu-panel > li.menu-item
		// }
		.header--big & {
			height: $nav-height--big;
			& > ul.menu-panel > li.menu-item {
				& a,
				& div.menu-item-link {
					color: $nav-btn-txt--big;
				}

				&.active {
					background: $nav-btn-active--big;
					& a,
					& div.menu-item-link {
						color: $nav-btn-txt-active--big;
					}
				}			
				&:hover {
					background-color: $nav-btn-hover--big;
					& a,
					& div.menu-item-link {
						color: $nav-btn-txt-hover--big;
					}
					// add in sub menu if customisation to big/small sub menu panels is required.
				}
			}
			
		}
		
	}
}
@import "./styles/variables.scss";
@import "./styles/media.scss";

html {
	height: 100%;
	font-size: 62.5%;
	box-sizing: border-box;
	line-height: 1.6;
    overflow: -moz-scrollbars-vertical; 	// crazy fix for lazy loading affecting (if not applied directly to) keen slider. Without it, the change...
    overflow-y: scroll;						// ... to scroll bar (appearing as lazy content loads) causes slide position to be incrementally out
}

/* montserrat-300 - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal; 
	font-display: swap;
	font-weight: 300;
	src: local('Montserrat'),
		 url('./fonts/montserrat-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		 url('./fonts/montserrat-v15-latin-300.woff') format('woff'), /* Modern Browsers */
		 url('./fonts/montserrat-v15-latin-300.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* RobotoFlex */
@font-face {
	font-family: 'RobotoFlex';
	src: 	url('./fonts/RobotoFlex.ttf') format('truetype supports variations'),
			url('./fonts/RobotoFlex.ttf') format('truetype-variations');
	font-weight: 1 1000;
	font-stretch: 25% 200%;
}

*, *:before, *:after {
	box-sizing: inherit;
	font-family: Montserrat, sans-serif;
}

body {
	margin: 0;
	padding: 0;
	font-family: Montserrat, sans-serif;
	font-weight: 100;
	position: relative;
	font-size: 1.4rem;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;
	color: $hero;
	cursor: pointer;
	&:hover {
		color: $hero-dark;
		text-decoration: underline;
	}
}

.App {
	// this forces the footer to fill page when page content is very short
	display: flex;
    flex-direction: column;
    min-height: 100vh;
}

// container for all non-header content. Includes translate for header resize.
div.non-header {
	display: flex;	// important for footer to grow on very short pages.
	flex-direction: column;
	flex-grow: 1;
	margin-top: calc( #{$main-top-small} - 5px);
	transition: transform $default-ease;	
	.header--big & {
		transform: translate(0, $header-height--diff);
	}
}


section {
	&.last {
		padding-bottom: 5em;
		margin-bottom: 0;
		@include for-tablet-landscape-up {
			padding-bottom: 5em;
			margin-bottom: 2em;
		}
	}
	&.top-gap {		
		padding-top: 5em;
		margin-top: 0;
		@include for-tablet-landscape-up {
			padding-top: 5em;
			margin-top: 2em;
		}
	}
}


.flx {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;	
	@include for-tablet-landscape-up {
		flex-direction: row;
	}
	&--centre {
		justify-content: center;
	}
}
.flx-cell {
	flex: 1 1 auto;
	margin: 0;
	width: 100%;
}
.flx-dir-col-lock {
	/* use of this on a box div will exclude switch to side padding on for-tablet-landscape-up. Search EX-COL-LOCK */
	flex-direction: column;
	align-items: center;
}
.flx-dir-row-lock {
	/* use of this on a box div will exclude switch to side padding on for-tablet-landscape-up. Search EX-COL-LOCK */
	flex-direction: row;
}

.box {
	width: 100%;
	max-width: $container-max-width;
	margin: 0 auto;
	padding: 0 $box-side-pad;	// changes with page width so starting point is a var

	@include for-tablet-portrait-up {
		padding: 0 calc(#{$box-side-pad}*1.5);
	}
	@include for-desktop-up {	
		padding: 0;
	}
}


.box .cell + .cell,
.box .cell.flx-order-2nd-in-column {							// mobile cells in col, use top padding. May be html first cell but labelled to move into second position so also add top margin
	margin-top: 2em;
}
@include for-tablet-landscape-up {								// > mobile cells in rows, use left margin except for first		:not(.flx-dir-col-lock)
	.box:not(.flx-dir-col-lock) .cell + .cell {					/* use of col-lock is excluded from switch to side padding as it will always be a column. Search EX-COL-LOCK */
		margin-left: 6em;
		margin-top: 0;
	}
	.box:not(.flx-dir-col-lock) .flx-order-2nd-in-column {		// where mFirst has cell first in html order pushed to second in col layout padded... remove the padding for desktop
		margin-top: 0;
	}
}


@include for-tablet-landscape-up {	
	.cell--third {
		max-width: 34%;
	}
	.cell--two-thirds {
		max-width: 66%;
	}
}


/* 		NOTE ON HEADINGS
		H1 and H6 are interchangeable so we can choose which one is sending the H1 signal for SEO.
		They behave differetly if they are in the .title-ribbon section or not.
*/

main section:not(.title-ribbon):not(.breadcrumb-trail) .box .cell  {	
	font-size: 1rem;
	//============================================
	& h1,
	& h6 {
		font-weight: 100;
		font-size: 2.6em;
		letter-spacing: -1px;
		line-height: 1.2;
		text-transform: uppercase;
		margin-top: 1em;
		margin-bottom: 0.8em;
		& span {
			font-weight: 600;
			white-space: nowrap;
			@include for-watch-only {
				white-space: normal;
			}
			font-size: 1.03em;
			@include for-tablet-landscape-up {
				font-size: 0.97em;
			}
			&.registered-trademark-symbol {
				font-weight: 100;
				font-size: 0.4em;
				position: relative;
				top: -1.2em;
				color: #BBB;
			}			
		}
		&.centred {
			text-align: center;
		}
	}
	& h2 {
		font-weight: 100;
		font-size: 1.8em;
		text-transform: uppercase;
		letter-spacing: -0.5px;
		color: $hero;
		line-height: 1.2;
		&.reverse {
			color: $alpha-white-06;
		}
		& span { 
			font-weight: 600;
		}
		@include for-tablet-landscape-up {		
			&:first-child {
				margin-top: -5px;				// Used to align heading to top of image when next to it.
			}
		}
	}
	& h3 {
		font-weight: 100;
		text-transform: uppercase;
		font-size: 1.8em;
		line-height: 1.2;
		letter-spacing: 0.05em;
		color: $mid-grey;
		margin: .1em 0;
		& span { font-weight: 100; }
	}
	& p,
	& ul > li {
		margin: 0.2em 0 0.5em 0;
		line-height: 1.6em;
		font-size: 1.6em;
	}
	& p {
		line-height: 1.4em;
		&.centred {
			text-align: center;
		}
	}
	& a {
		text-decoration: none;
		cursor: pointer;
	}

	@include for-watch-only {			// LIGHTHOUSE: satisfy google lighthouse contrast requirements.
		& p,
		& ul > li {	
			font-size: 2.4em;
		}
		& h2 { 
			font-size: 2.4em;
			&.reverse {
				color: $solid-white;
				opacity: 1;
			}
		}
	}
	@include for-tablet-portrait-up {	
		& h1,& h6 	{
			font-size: 3.2em;	
			margin-top: 1.3em;
			margin-bottom: 1.2em;
		}	
	}
	@include for-tablet-landscape-up {		
		& h1,& h6 	{		font-size: 4em;			}	
		& h2 		{		font-size: 2.7em;	}
		& h3 		{		font-size: 1.8rem;		}
		& p,
		& ul > li {
			margin: 0.2em 0 0.5em 0;
			&:first-child {
				margin-top: 0;
			}
		}
		& a {
			font-size: 1.0em;
			line-height: 1.9em;
		}
	}
	@include for-desktop-up {
		& h1,& h6 	{		font-size: 5.5em;	}
	}
	//============================================
}








// LAST GENERIC ADJUSTERS
.panel-section--mb { margin-bottom: 0; }
@include for-tablet-landscape-up {
	.panel-section--mb { margin-bottom: 6em; }
}


.center { text-align: center; }
.hero-text { color: $hero; }
.hero-dark-text { color: $hero-dark; }

.sml-left--lrg-center > * { text-align: left; }
@include for-tablet-portrait-up {
	.sml-left--lrg-center > * { text-align: center; }
}



section.marker {
	color: #E0E0E0;
	@include for-phone-only {					div::after { content: 'for-phone-only'; }						}
	@include for-tablet-portrait-up {			div::after { content: 'for-tablet-portrait-up'; }				}
	@include for-full-menu-up {					div::after { content: 'for-full-menu-up'; }						}	
	@include for-tablet-landscape-up {			div::after { content: 'for-tablet-landscape-up'; }				}
	@include for-desktop-up {					div::after { content: 'for-desktop-up'; }						}
	@include for-big-desktop-up {				div::after { content: 'for-big-desktop-up'; }					}
}

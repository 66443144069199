@import "../../styles/variables.scss";
@import "../../styles/media.scss";




.container.privacy {

	& ol {
		list-style-type: none;
		counter-reset: item;
		margin: 0;
		padding: 0;
	}
	& ol > li {
		display: table;
		counter-increment: item;
		margin-bottom: 0.6em;
	}
	& ol li		{	font-weight: 600;	}
	& ol li ol li	{	font-weight: normal;	}
	& ol > li:before {
		content: counters(item, ".") ". ";
		display: table-cell;
		padding-right: 1em;
	}
	& li ol > li {
		margin: 0;
	}
	& li ol > li:before {
		content: counters(item, ".") " ";
	}
}
@import "../../styles/variables.scss";
@import "../../styles/media.scss";

main section:not(.title) .box .cell .cta,
.cta {
	font-family: Montserrat, sans-serif;
	display: inline-flex;
	flex-direction: row;
	border-radius: 4px;
	cursor: pointer;
	align-self: center;
	background: $hero;
	margin: 2em 0;
	&:hover {
		background: $hero-hover;
	}
	&.cta--contrast {
		background: $contrast;
		&:hover {
			background: $contrast--hover;
		}
	}
	&.cta--support  {
		background: $support;
		&:hover {
			background: $support--hover;
		}
	}
	&.cta--support-bright  {
		background: $support--bright;
		&:hover {
			background: $support--hover;
		}
	}
	&.cta--support-bright--focus-bright  {
		background: $support--bright;
		&:hover {
			background: $focus--bright;
		}
	}
	&.cta--focus-bright--focus  {
		background: $focus--bright;
		&:hover {
			background: $focus;
		}
	}
	&.cta--focus  {
		background: $focus;
		&:hover {
			background: $focus--hover;
		}
	}

	& a.cta--link,
	& a.cta--link:visited,
	& a.cta--link:hover {
		text-decoration: none;
		color: #FFFFFF;
		padding: .9em 2.5em .9em 2.5em;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		font-weight: 600;
		font-size: 1.2em;
		
		@include for-tablet-portrait-up {
			font-size: 1.3em;
		}
		
	}
	cursor: pointer;

	// Squish long links to not wrap on mobiles
	&--long a.cta--link,
	&--long a.cta--link:visited,
	&--long a.cta--link:hover {
		letter-spacing: 0;
		padding: .9em 2.2em .9em 2.2em;
		word-spacing: 0.2em;
	}
}

@import "../../styles/variables.scss";
@import "../../styles/media.scss";



.header-item--brand {
	transition: transform $rapid-ease;
	max-width: $header-logo-max-width;								// TRANSFORM: this is the 'originalWidth'
	padding-bottom: $header-logo-base-pad;
		& a {
		text-decoration: none;
		display: flex;
		& .brand-box {
			align-items: center;
			font-size: 1.6rem;
			//max-width: $header-logo-max-width;
			& svg {
				&.brand-logo {
					width: 100%; 							// marry with $header-brand-height--small - doesn't have to be same but would normally be no bigger (but coould be)					
					& .logo-fg {
						fill: $hero;//solid-white;
						//transition: fill $default-ease 400ms;
					}
				}
			}
		}
	}
}
.header--big .header-item--brand {
	//transform: scale(1.48)	translate( 16.21%, 0 );  // TRANSFORM:  x = (((originalWidth * Growth) - originalWidth) /2)  / finalWidth    <<< THIS WORKS BUT IS MORE COMPLICATED THAN BELOW.
	transform: translate( $header-logo-scale-position-x, $header-logo-base-pad*2 ) scale($header-logo-scale)	;  // TRANSFORM: O-R-D-E-R---C-O-U-N-T-S  x = (Growth / 2)
	transition: transform 500ms cubic-bezier(0.38, 0, 0.82, 0.73);//$casual-ease;
	// & a  .brand-box svg.brand-logo .logo-fg {
	// 	fill: $hero;
	// }
}

section.hero-bg {
	& .brand-box {
	width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 1.6rem;
		& svg {
			&.brand-logo {
				max-width: 85%;
				& .logo-fg {
					fill: $solid-white;
				}
			}
		}
	}
}

section {
	& .brand-box {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 1.6rem;
		& svg {
			&.brand-logo {
				max-width: 85%;
				//transition: width $default-ease;
				& .logo-fg {
					fill: $hero;
				}
			}
		}
	}
}






.gm-style-iw-d {
	
    max-height: 176px; 			// for FireFox to avoid scroll within info window
	& .brand-box {
		max-width: 220px;		// for FireFox to contain the svg logo
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 1.6rem;
		& svg {
			&.brand-logo {
				max-width: 85%;
				//transition: width $default-ease;
				& .logo-fg {
					fill: $hero;
				}
			}
		}
	}
}




// @include for-watch-only {			// LIGHTHOUSE: satisfy google lighthouse contrast requirements.
// 	.header-item--brand a {
// 		& .brand-box svg.brand-logo .logo-bg {
// 			fill: $hero-dim;
// 		}
// 		& .brand-masthead {
// 			font-size: 1.9em;
// 		}
// 	}
// 	section .brand-box  .brand-masthead {
// 		font-size: 3.5em;
// 		& .brand-masthead-suplementary {
// 			& .brand-masthead-secondary,
// 			& .brand-masthead-tertiary {
// 				color: #FFF;
// 			}
// 		}
// 	}
// }







.footer-brand.brand-box {
	align-items: center;
	//font-size: 1.6rem;
	& svg {
		&.brand-logo {
			width: 100%;
			max-width: 500px;
	//		transition: min-width $default-ease;
			& .logo-fg, .masthed-byline {
				fill: $solid-white;
			}
			& .logo-bg, .registered-trademark-symbol, .trademark-symbol {
				fill: $alpha-white-04;
			}
		}
	}
	& .brand-masthead {
		@media ($debug) {
			background-color: rgba($color: #1eff00, $alpha: 0.2);
		}
		color: #000;
		font-family: Montserrat, san-serif;
		font-size: 8vw;							// on mobile and below, scale with screen width
		@include for-tablet-portrait-up {		// fixed size above mobile width
			font-size: 2.7em;		
		}
		line-height: 0.8em;
		margin-left: -0.1em;
		margin-top: 0.3em;
		text-transform: uppercase;
	//	transition: font-size $default-ease;
		& .brand-masthead-primary {
			letter-spacing: 0.05em;
			color: $solid-white;
			opacity: 0.8;
		}
		& .brand-masthead-suplementary {
			display: flex;
			justify-content: flex-start;
			font-size: 0.48em;
			margin-left: 0.12em;
			& .brand-masthead-secondary {
				letter-spacing: 0.05em;
				color: $solid-white;
				opacity: 0.5;
				margin-right: 1em;
			}
			& .brand-masthead-tertiary {
				letter-spacing: 0.04em;
				color: $solid-white;
				opacity: 0.5;
			}
		}
	}
	
}